import { Link } from "gatsby";
import * as React from "react";
import { Card, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { graphql } from "gatsby"

export default function Thanks() {


    return (
        <Layout>
            <main>
                <Container fluid={true}>
                    <h5 align="center">Congratulations! You have successfully submitted your application</h5>
                </Container>
            </main>
        </Layout>
    );
}
